












































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DialogComponent from '@/components/dialog.component.vue';
import { ExamReport } from '@/types/questions.type';

@Component({
  components: {
    DialogComponent,
  },
})
export default class ExamDetailsDialog extends mixins() {
    @Prop(Object) readonly examDetails!: ExamReport;

    showExamDetailsDialog = false;

    @Watch('examDetails')
    onQuestionIdChange() {
      this.showExamDetailsDialog = true;
    }

    get showTurn() {
      return this.examDetails.turn && Object.keys(this.examDetails.turn).length > 0;
    }
}

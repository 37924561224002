











































































































































































































































































































import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import DialogComponent from '@/components/dialog.component.vue';
import ScopeComponent from '@/components/scope.component.vue';

import SimulationDialogComponent from './QuestionSimulation.dialog.vue';
import QuestionsProvider from '../../providers/questions.provider';
import SelectedCourse from '../SelectedCourse.component.vue';

import {
  QuestionTypes,
  BasicOption,
  DetailedQuestion,
  QuestionDetailsResponse,
  QuestionHistory,
} from '../../types/questions.type';
import { Lang } from '../../types/general.type';

import {
  APPIDS,
  Rights,
  QUESION_TYPES,
  QUESTIONS_STATUS,
  QUESTIONS_ROUTES_NAMES,
} from '../../enums';
import QuestionView from './QuestionView.component.vue';
import Toaster from '../../mixins/toaster.mixin';
import QuestionVersioningReports from './QuestionVersioningReports.component.vue';

@Component({
  components: {
    QuestionView,
    DialogComponent,
    SimulationDialogComponent,
    ScopeComponent,
    SelectedCourse,
    QuestionVersioningReports,
  },
})
export default class QuestionDetails extends mixins(Toaster) {
  appId = APPIDS.CREATE_QUESTIONS;

  name = 'questionDetails';

  courseId = this.$route?.params.courseId;

  questionType = this.$route?.params.questionType || '';

  BackUrl = `/questions/${this.courseId}/questions-list?typeId=${this.questionType
    || QUESION_TYPES.MCQ.id}&limit=10&page=1`;

  questionId = '';

  loading = true;

  serverError = null;

  loadingReview = false;

  loadingDelete = false;

  loadingOpen = false;

  showDeleteConfirm = false;

  showOpenConfirm = false;

  showAddDialog = false;

  questionTypes: QuestionTypes[] = [];

  toAddQuestionType = '';

  rights: string[] = [];

  question: DetailedQuestion = {};

  questionVersionData: DetailedQuestion | null = null;

  selectedQuesVersion: number | null = null;

  evaluationItems: BasicOption[] = [];

  questionHistory: QuestionHistory[] = [];

  questionsIdList: string[] = [];

  enableNextQuestion = true;

  showNextQuestion = false;

  enablePreviousQuestion = true;

  showPreviousQuestion = false;

  $options!: {
    filters: {
      servertranslate;
    };
  };

  tab: string | null = '';

  tabsItems: string[] = ['titles.CURRENT_QUESTION'];

  questionVersionNumbers: number[] = [];

  openedDataPanelHeader: number[] = [];

  versioningLoading = true;

  servertranslate = this.$options.filters.servertranslate;

  $refs!: {
    simulationDialog: SimulationDialogComponent;
  };

  mounted() {
    this.questionsIdList = this.$store.state.navigatQuestionsListId.questionsIdLists[String(this.appId)] || [];
    this.loadQuestion(this.$route);
  }

  loadQuestion(route) {
    this.courseId = route.params.courseId;
    this.questionId = route.params.questionId;
    this.questionType = route.params.questionType;

    this.getQuestionDetails();
  }

  beforeRouteUpdate(to, from, next) {
    this.loadQuestion(to);
    next();
  }

  openAddPage() {
    if (this.toAddQuestionType === QUESION_TYPES.CASE_STUDY.id) {
      this.$router.push({
        name: 'addCaseStudy',
        params: {
          courseId: this.courseId,
        },
      });
    } else {
      this.$router.push({
        name: QUESTIONS_ROUTES_NAMES.ADD,
        params: {
          courseId: this.courseId,
          questionType: this.toAddQuestionType,
        },
      });
    }
    this.showAddDialog = false;
  }

  get isCaseStudy() {
    return this.questionType === QUESION_TYPES.CASE_STUDY.id;
  }

  get questionStatus() {
    return this.question.status?.id;
  }

  get canAskReview() {
    return (
      this.questionStatus === QUESTIONS_STATUS.COMPLETE
      && ((this.question.isOwner && this.canAdd) || this.canEditAll)
    );
  }

  get canOpen() {
    return this.questionStatus === QUESTIONS_STATUS.APPROVED && this.canEditAll;
  }

  get canAdd() {
    return this.rights.includes(Rights.add);
  }

  get canDelete() {
    if (!this.question.isOwner) {
      return this.canDeleteAll;
    }
    return this.questionStatus === QUESTIONS_STATUS.APPROVED ? this.canDeleteAll : this.canAdd;
  }

  get canEdit() {
    if (!this.question.isOwner) {
      return this.canEditAll;
    }
    return this.questionStatus === QUESTIONS_STATUS.APPROVED ? this.canEditAll : this.canAdd;
  }

  get canEditAll() {
    return this.rights.includes(Rights.editAll);
  }

  get canDeleteAll() {
    return this.rights.includes(Rights.deleteAll);
  }

  get isSuperUser() {
    return this.canEditAll || this.canDeleteAll;
  }

  onBack() {
    const appFilters = this.$store.state.filters.appFilters[this.appId];
    this.$store.commit('clearListForApp', String(this.appId));
    this.$router.push({
      name: QUESTIONS_ROUTES_NAMES.QUESTIONS_LIST,
      query: appFilters,
      params: {
        courseId: this.courseId,
      },
    });
  }

  get canSimulate() {
    return (
      this.rights.includes(Rights.view)
      && this.questionStatus !== QUESTIONS_STATUS.INCOMPLETE
    );
  }

  openAddQuestionDialog() {
    this.toAddQuestionType = this.questionType;
    this.showAddDialog = true;
  }

  nextQuestion() {
    const questionIdindex: number = this.questionsIdList.indexOf(String(this.questionId));

    const nextQuestionId = this.questionsIdList[questionIdindex + 1];

    this.$router.push({
      name: 'question-details',
      params: {
        questionId: nextQuestionId,
        questionType: this.questionType,
      },
    });

    this.tab = null;
  }

  previousQuestion() {
    const questionIdindex: number = this.questionsIdList.indexOf(String(this.questionId));

    const previousQuestionId = this.questionsIdList[questionIdindex - 1];

    this.$router.push({
      name: 'question-details',
      params: {
        questionId: previousQuestionId,
        questionType: this.questionType,
      },
    });

    this.tab = null;
  }

  async askReviewQuestion() {
    try {
      this.loadingReview = true;
      const { message } = await QuestionsProvider.askForQuestionReview(
        this.appId,
        this.courseId,
        this.questionId,
        { questionType: this.questionType },
      );
      this.loadingReview = false;
      this.successToaster(message as Lang, { duration: 5000 });
      setTimeout(() => {
        this.$router.go(0);
      }, 1000);
    } catch (err) {
      this.loadingReview = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  async openQuestion() {
    try {
      this.loadingOpen = true;
      const { message } = await QuestionsProvider.openQuestion(
        this.appId,
        this.courseId,
        this.questionId,
        { questionType: this.questionType },
      );
      this.successToaster(message as Lang, { duration: 5000 });
      setTimeout(() => {
        this.$router.go(0);
      }, 1000);
    } catch (err) {
      this.errorToaster(err as Lang, { duration: 5000 });
    } finally {
      this.loadingOpen = false;
    }
  }

  openEditPage() {
    if (!this.isCaseStudy) {
      this.$router.push({
        name: QUESTIONS_ROUTES_NAMES.EDIT,
        params: {
          courseId: this.courseId,
          questionId: this.questionId,
          questionType: this.questionType,
        },
      });
    } else {
      this.$router.push({
        name: 'editCaseStudy',
        params: {
          courseId: this.courseId,
          questionId: this.questionId,
          questionType: this.questionType,
        },
      });
    }
  }

  openCopyPage() {
    if (!this.isCaseStudy) {
      this.$router.push({
        name: QUESTIONS_ROUTES_NAMES.COPY,
        params: {
          courseId: this.courseId,
          questionId: this.questionId,
          questionType: this.questionType,
        },
      });
    } else {
      this.$router.push({
        name: QUESTIONS_ROUTES_NAMES.COPY_CASE_STUDY,
        params: {
          courseId: this.courseId,
          questionId: this.questionId,
        },
      });
    }
  }

  async deleteQuestion() {
    try {
      this.loadingDelete = true;
      await QuestionsProvider.deleteQuestion(this.appId, this.courseId, this.questionId, {
        questionType: this.questionType,
      });
      this.loadingDelete = false;
      this.showDeleteConfirm = false;
      this.onBack();
    } catch (err) {
      this.loadingDelete = false;
    }
  }

  get questionTypesTranslated() {
    return this.questionTypes?.map((item) => ({
      translatedName: this.$options.filters?.servertranslate(item.baName),
      id: item.baID,
    }));
  }

  async getQuestionDetails() {
    this.serverError = null;
    try {
      const {
        question,
        evaluationItems,
        rights,
        questionHistory,
        questionTypes,
        questionVersions,
      }: QuestionDetailsResponse = await QuestionsProvider.getQuestionDetails(
        this.appId,
        this.courseId,
        this.questionId,
        { questionType: this.questionType },
      );
      this.rights = rights as string[];
      this.question = question as DetailedQuestion;
      this.questionTypes = questionTypes as QuestionTypes[];
      this.evaluationItems = evaluationItems as BasicOption[];
      this.questionHistory = questionHistory as QuestionHistory[];
      this.questionVersionNumbers = questionVersions as number[];
      this.loading = false;

      if (question && question.quesVersion) {
        this.questionVersionNumbers.push(question.quesVersion as number);
        if (!this.tabsItems.includes('titles.OLD_VERSIONS')) this.tabsItems.push('titles.OLD_VERSIONS');
      }

      this.enableNextQuestion = this.questionsIdList.indexOf(String(this.questionId)) < this.questionsIdList.length - 1;
      this.enablePreviousQuestion = this.questionsIdList.indexOf(String(this.questionId)) > 0;

      this.showNextQuestion = this.questionsIdList.includes(String(this.questionId)) && this.questionsIdList.length > 1;
      this.showPreviousQuestion = this.questionsIdList.includes(String(this.questionId)) && this.questionsIdList.length > 1;
    } catch (err) {
      this.errorToaster(err as Lang, { duration: 5000 });
      this.$store.commit('clearListForApp', String(this.appId));
      this.serverError = err;
      this.loading = false;
    }
  }

  opensimulationDialogDialog() {
    if (this.$refs.simulationDialog) {
      this.$refs.simulationDialog.open();
    }
  }

  async getQuestionVersionData(header): Promise<void> {
    try {
      if (header !== 0) {
        this.versioningLoading = true;
        if (this.selectedQuesVersion) {
          if (this.selectedQuesVersion === this.question.quesVersion) {
            this.questionVersionData = this.question;
          } else if (!this.questionVersionData || this.questionVersionData.quesVersion !== this.selectedQuesVersion) {
            const {
              question,
            } = await QuestionsProvider.getQuestionVersion(this.appId, this.courseId, this.questionId, this.selectedQuesVersion);
            this.questionVersionData = question as DetailedQuestion;
          }
        }
      }
      this.versioningLoading = false;
    } catch (err) {
      this.questionVersionData = null;
      this.versioningLoading = false;
    }
  }

  resetSelectedVersion(index: number | null = null): void {
    if (index === 0) {
      this.selectedQuesVersion = null;
    } else if (index === 1) {
      this.selectedQuesVersion = this.question.quesVersion as number;
    }
    this.openedDataPanelHeader = [];
  }

  @Watch('selectedQuesVersion')
  onSelectedQuesVersionChange() {
    this.questionVersionData = null;
  }

  @Watch('questionId')
  onQuestionIdChange() {
    this.questionVersionData = null;
  }
}

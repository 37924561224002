





















































































































































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import IlosComponet from '@/shared/ilos-view-component.vue';
import MathJaxMixin from '@/mixins/mathJax.mixin';
import {
  DetailedQuestion,
  BasicOption,
  QuestionHistory,
} from '../../types/questions.type';
import SubQuestionsPanels from './SubQuestionsPanels.component.vue';
import ViewMatchingColumns from './matching/viewMatchingColumns.vue';
import {
  QUESION_TYPES,
  QUESTIONS_STATUS,
  VALID_DIFFICULTY_RANGE,
  VALID_TIME_RANGE,
} from '../../enums';
import Utils from '../../shared/utils';

@Component({
  components: {
    SubQuestionsPanels,
    ViewMatchingColumns,
    IlosComponet,
  },
})
export default class QuestionView extends mixins(MathJaxMixin) {
  @Prop(Object) readonly questionData!: DetailedQuestion;

  @Prop(Boolean) readonly questionVersionView!: boolean;

  @Prop(Array) readonly evaluationItems!: BasicOption[];

  @Prop(Array) readonly questionHistory!: QuestionHistory[];

  @Prop({ default: false }) readonly hideTimeLine!: boolean;

  openNodes= [];

  get isEssay(): boolean {
    return this.questionData.basicData?.type?.id === QUESION_TYPES.ESSAY.id;
  }

  @Watch('questionData')
  onQuestionUpdate() {
    setTimeout(() => {
      this.renderEquations();
    }, 0);
  }

  getStringTime(averageTime: string | number): string {
    return Utils.getStringTime(averageTime);
  }

  get difficultyNeedToFix(): boolean {
    if (this.isCaseStudyQuestion) {
      let needToFix = false;
      const subQuestionsLength = this.questionData?.subQuestions?.length as number;
      for (let i = 0; i < subQuestionsLength; i += 1) {
        const sub = this.questionData?.subQuestions
          ? this.questionData?.subQuestions[i]
          : {};

        const difficulty = sub?.difficulty?.value as number;
        const averageDifficulty = sub?.averageDifficulty as number;
        const topDifficulty = difficulty ? difficulty + difficulty * VALID_DIFFICULTY_RANGE : VALID_DIFFICULTY_RANGE;
        const bottomDifficulty = difficulty ? difficulty - difficulty * VALID_DIFFICULTY_RANGE : 0;
        needToFix = (!!averageDifficulty || averageDifficulty === 0)
          && (topDifficulty < averageDifficulty || bottomDifficulty > averageDifficulty);
        if (needToFix) break;
      }
      return needToFix;
    }
    const difficulty = this.questionData?.basicData?.difficulty?.value as number;
    const averageDifficulty = this.questionData?.averageDifficulty as number;
    const topDifficulty = difficulty ? difficulty + difficulty * VALID_DIFFICULTY_RANGE : VALID_DIFFICULTY_RANGE;
    const bottomDifficulty = difficulty ? difficulty - difficulty * VALID_DIFFICULTY_RANGE : 0;
    return (!!averageDifficulty || averageDifficulty === 0)
        && (topDifficulty < averageDifficulty || bottomDifficulty > averageDifficulty);
  }

  get timeNeedToFix(): boolean {
    if (this.isCaseStudyQuestion) {
      let needToFix = false;
      const subQuestionsLength = this.questionData?.subQuestions?.length as number;
      for (let i = 0; i < subQuestionsLength; i += 1) {
        const sub = this.questionData?.subQuestions
          ? this.questionData?.subQuestions[i]
          : {};

        const time = parseFloat(sub?.timeInMinutes as unknown as string);
        const averageTime = parseFloat(sub?.averageTime as unknown as string);
        const topTime = time ? time + time * VALID_TIME_RANGE : VALID_TIME_RANGE;
        const bottomTime = time ? time - time * VALID_TIME_RANGE : 0;
        needToFix = (!!averageTime || averageTime === 0) && (topTime < averageTime || bottomTime > averageTime);
        if (needToFix) break;
      }
      return needToFix;
    }
    const time = this.questionData?.basicData?.timeInMinutes as number;
    const averageTime = this.questionData?.averageTime as number;
    const topTime = time ? time + time * VALID_TIME_RANGE : VALID_TIME_RANGE;
    const bottomTime = time ? time - time * VALID_TIME_RANGE : 0;
    return (!!averageTime || averageTime === 0) && (topTime < averageTime || bottomTime > averageTime);
  }

  get appDrawerOpen() {
    return !this.$store.state.app.mini;
  }

  get flagedEvaluations() {
    return this.evaluationItems ? this.evaluationItems.map((evaluationItem) => ({
      ...evaluationItem,
      checked: this.questionData.evaluation?.includes(
        evaluationItem.id?.toString() as string
      ),
    })) : [];
  }

  get reviewedQuestion() {
    return [QUESTIONS_STATUS.APPROVED, QUESTIONS_STATUS.REJECTED].includes(this.questionData.status?.id as string);
  }

  get showSubQuestions() {
    return (
      this.questionData.basicData?.type?.id === QUESION_TYPES.CASE_STUDY.id
      && this.questionData.subQuestions?.length
    );
  }

  get isCaseStudyQuestion() {
    return this.questionData.basicData?.type?.id === QUESION_TYPES.CASE_STUDY.id;
  }

  get showMatchingAnswers() {
    return (
      this.questionData.basicData?.type?.id === QUESION_TYPES.MATCHING.id

    );
  }
}


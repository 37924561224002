var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panels',{staticClass:"my-3",model:{value:(_vm.itemAnalysisHeader),callback:function ($$v) {_vm.itemAnalysisHeader=$$v},expression:"itemAnalysisHeader"}},[_c('v-expansion-panel',{staticClass:"my-1 grey lighten-4"},[_c('v-expansion-panel-header',{staticClass:"text-subtitle-1 font-weight-bold secondary--text",on:{"click":function($event){return _vm.getQuestionVersionData(_vm.itemAnalysisHeader)}}},[_c('h4',[_vm._v(_vm._s(_vm.$t("labels.ITEM_ANALYSIS")))])]),_c('v-expansion-panel-content',{staticClass:"text-gray"},[_c('v-divider'),(_vm.questionVersionReports && _vm.questionVersionReports.reports && _vm.questionVersionReports.reports.itemAnalysisReport && !!_vm.itemAnalysisReportLength)?_c('v-card-text',[_vm._l((_vm.questionVersionReports.reports.itemAnalysisReport),function(report,index){return [_c('v-data-table',{key:("item-analysis-" + index),staticClass:"grey lighten-4 clickable",attrs:{"items":report,"headers":_vm.getItemAnalysisHeaders(index, report),"loading-text":_vm.$t('labels.loading'),"item-class":_vm.getGiveFullMarkClass,"no-data-text":_vm.$t('labels.noData'),"disable-pagination":"","hide-default-footer":true},on:{"click:row":_vm.showExamDetails},scopedSlots:_vm._u([{key:"item.examTitle",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"word-break"},on),[_vm._v(_vm._s(_vm.getExamTitle(item.examId)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.SHOW_EXAM_DETAILS")))])])]}},{key:"item.modelIndex",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.modelIndex) + 1)+" ")]}},{key:"item.studentsCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.studentsCount)+" ")]}},([_vm.QUESION_TYPES.CASE_STUDY.id, _vm.QUESION_TYPES.MATCHING.id].includes(_vm.question.basicData.type.id))?{key:"item.subQuestionTitle",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.getSubQuestionTitle(_vm.question.basicData.type.id, item, index))+" ")]}}:null,{key:"item.discriminationIndex",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.discriminationIndex)+" ")]}},{key:"item.difficultyIndex",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.difficultyIndex + ' % ')+" ")]}},_vm._l((_vm.answersCount[index]),function(answer,i){return {key:("item.answers[" + i + "]"),fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.answers[("" + i)] ? (item.answers[("" + i)] + ' % ') : ' - ')+" ")]}}})],null,true)})]}),[_c('v-data-table',{key:"item-analysis-average",staticClass:"grey lighten-4 mt-5 pt-5",attrs:{"items":_vm.itemAnalysisAverages,"headers":_vm.itemAnalysisAveragesHeaders,"loading-text":_vm.$t('labels.loading'),"no-data-text":_vm.$t('labels.noData'),"disable-pagination":"","hide-default-footer":true},scopedSlots:_vm._u([([_vm.QUESION_TYPES.MATCHING.id, _vm.QUESION_TYPES.CASE_STUDY.id].includes(_vm.question.basicData.type.id))?{key:"item.subQuestionTitle",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.getSubQuestionTitle(_vm.question.basicData.type.id, item, index))+" ")]}}:null,{key:"item.studentsCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.studentsCount)+" ")]}},{key:"item.discriminationIndex",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.discriminationIndex)+" ")]}},{key:"item.difficultyIndex",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.difficultyIndex + ' % ')+" ")]}}],null,true)})]],2):(_vm.versioningLoading)?_c('v-card-text',{staticClass:"text-center my-5"},[_vm._v(_vm._s(_vm.$t('labels.loading')))]):_c('v-card-text',{staticClass:"text-center my-5"},[_vm._v(_vm._s(_vm.$t('labels.noData')))])],1)],1)],1),_c('v-expansion-panels',{staticClass:"my-3",model:{value:(_vm.answerTimeDistributionHeader),callback:function ($$v) {_vm.answerTimeDistributionHeader=$$v},expression:"answerTimeDistributionHeader"}},[_c('v-expansion-panel',{staticClass:"my-1 grey lighten-4"},[_c('v-expansion-panel-header',{staticClass:"text-subtitle-1 font-weight-bold secondary--text",on:{"click":function($event){return _vm.getQuestionVersionData(_vm.answerTimeDistributionHeader)}}},[_c('h4',[_vm._v(_vm._s(_vm.$t("labels.ANSWER_TiME_DISTRIBUTION")))])]),_c('v-expansion-panel-content',{staticClass:"text-gray"},[_c('v-divider'),(_vm.questionVersionReports && _vm.questionVersionReports.reports && _vm.questionVersionReports.reports.answerTimeDistributionReport && !!_vm.answerTimeDistributionReportLength)?_c('v-card-text',[_vm._l((_vm.questionVersionReports.reports.answerTimeDistributionReport),function(report,index){return [_c('v-data-table',{key:("time-distribution-" + index),staticClass:"grey lighten-4 clickable",attrs:{"items":report,"item-class":_vm.getGiveFullMarkClass,"headers":_vm.answerTimeDistributionHeaders,"loading-text":_vm.$t('labels.loading'),"no-data-text":_vm.$t('labels.noData'),"disable-pagination":"","hide-default-footer":true},on:{"click:row":_vm.showExamDetails},scopedSlots:_vm._u([{key:"item.examTitle",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"word-break"},on),[_vm._v(_vm._s(_vm.getExamTitle(item.examId)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.SHOW_EXAM_DETAILS")))])])]}},{key:"item.modelIndex",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.modelIndex) + 1)+" ")]}},{key:"item.studentsCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.studentsCount)+" ")]}},(_vm.QUESION_TYPES.CASE_STUDY.id === _vm.question.basicData.type.id)?{key:"item.subQuestionTitle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSubQuestionTitle(_vm.question.basicData.type.id, item))+" ")]}}:null,{key:"item.minTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.minTime)+" ")]}},{key:"item.maxTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.maxTime)+" ")]}},{key:"item.theMean",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.theMean)+" ")]}},{key:"item.theMedian",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.theMedian)+" ")]}},{key:"item.theMode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.theMode)+" ")]}},{key:"item.standardDeviation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.standardDeviation)+" ")]}},{key:"item.coefficientOfVariation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.coefficientOfVariation)+" ")]}}],null,true)})]}),[_c('v-data-table',{key:"time-distribution-averages",staticClass:"grey lighten-4 mt-5 pt-5",attrs:{"items":_vm.answerTimeDistributionAverages,"headers":_vm.answerTimeDistributionAveragesHeaders,"loading-text":_vm.$t('labels.loading'),"no-data-text":_vm.$t('labels.noData'),"disable-pagination":"","hide-default-footer":true},scopedSlots:_vm._u([(_vm.QUESION_TYPES.CASE_STUDY.id === _vm.question.basicData.type.id)?{key:"item.subQuestionTitle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSubQuestionTitle(_vm.question.basicData.type.id, item))+" ")]}}:null,{key:"item.examsCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.examsCount)+" ")]}},{key:"item.studentsCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.studentsCount)+" ")]}},{key:"item.minTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.minTime)+" ")]}},{key:"item.maxTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.maxTime)+" ")]}},{key:"item.theMean",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.theMean)+" ")]}},{key:"item.theMedian",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.theMedian)+" ")]}},{key:"item.theMode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.theMode)+" ")]}},{key:"item.standardDeviation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.standardDeviation)+" ")]}},{key:"item.coefficientOfVariation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.coefficientOfVariation)+" ")]}}],null,true)})]],2):(_vm.versioningLoading)?_c('v-card-text',{staticClass:"text-center my-5"},[_vm._v(_vm._s(_vm.$t('labels.loading')))]):_c('v-card-text',{staticClass:"text-center my-5"},[_vm._v(_vm._s(_vm.$t('labels.noData')))])],1)],1)],1),_c('ExamDetailsDialog',{attrs:{"examDetails":_vm.examDetails}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }





















































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  AnswerTimeDistributionReport,
  DetailedQuestion,
  ExamReport,
  ItemAnalysisReport,
  QuestionReportsResponse,
  QuestionTypeKeyFunction,
} from '../../types/questions.type';
import {
  ANSWER_CHOICES, QUESION_TYPES,
} from '../../enums';
import QuestionsProvider from '../../providers/questions.provider';
import ExamDetailsDialog from './ExamDetailsDialog.compnent.vue';

@Component({
  components: {
    ExamDetailsDialog,
  },
})
export default class QuestionVersioningReports extends mixins() {
  questionVersionReports: QuestionReportsResponse | null = null;

  itemAnalysisAverages: ItemAnalysisReport[] = [];

  answerTimeDistributionAverages: AnswerTimeDistributionReport[] = [];

  @Prop(Object) readonly question!: DetailedQuestion;

  @Prop(String) readonly appId!: string;

  @Prop(String) readonly courseId!: string;

  @Prop(String) readonly questionId!: string;

  @Prop(Number) readonly selectedQuesVersion!: number;

  answersCount: number[] = [];

  QUESION_TYPES = QUESION_TYPES;

  itemAnalysisHeader = [];

  answerTimeDistributionHeader = [];

  examDetails: ExamReport = {};

  exams: ExamReport[] = [];

  versioningLoading = true;

  headerClass = 'grey darken-4 white--text pa-3';

  averagesHeaderClass = 'blue darken-4 white--text pa-3';

  fractionNumber = 2;

  getGiveFullMarkClass(item: ItemAnalysisReport | AnswerTimeDistributionReport): string {
    return item.giveFullMark ? 'error--text' : '';
  }

  @Watch('selectedQuesVersion')
  onSelectedQuesVersionChange() {
    this.resetQuestionVersionReports();
  }

  @Watch('questionId')
  onQuestionIdChange() {
    this.resetQuestionVersionReports();
    this.itemAnalysisAverages = [];
    this.answerTimeDistributionAverages = [];
  }

  showExamDetails(item: ItemAnalysisReport | AnswerTimeDistributionReport): void {
    if (this.exams && this.exams.length > 0) {
      const examRepors = this.exams.find((exam) => exam.examId === item.examId);
      if (examRepors) {
        this.examDetails = {
          ...examRepors as ExamReport,
          modelIndex: item.modelIndex,
          studentsCount: item.studentsCount,
          giveFullMark: item.giveFullMark,
        };
      }
    }
  }

  getExamTitle(examId: string): string {
    const examReport = this.exams ? this.exams.find((exam) => exam.examId === examId) : {};
    return examReport?.examTitle as string;
  }

  getSubQuestionTitle(questiontype: string, item: ItemAnalysisReport | AnswerTimeDistributionReport, index: number | null = null): string {
    if (questiontype === QUESION_TYPES.CASE_STUDY.id) {
      const subQuestionTitle = item.subQuestionType && QUESION_TYPES[item.subQuestionType]
        ? (QUESION_TYPES[item.subQuestionType] as QuestionTypeKeyFunction)()?.defaultText
        : {};
      return this.$options.filters?.servertranslate(subQuestionTitle);
    } if (questiontype === QUESION_TYPES.MATCHING.id) {
      return (index as number + 1) as unknown as string;
    }
    return '';
  }

  resetQuestionVersionReports(): void {
    this.questionVersionReports = null;
    this.itemAnalysisHeader = [];
    this.answerTimeDistributionHeader = [];
  }

  async getQuestionVersionData(header) {
    try {
      if (header !== 0) {
        this.versioningLoading = true;
        if (this.selectedQuesVersion
          && (!this.questionVersionReports || String(this.questionVersionReports.quesVersion) !== String(this.selectedQuesVersion))) {
          const result = await QuestionsProvider.getQuestionVersionReports(this.appId, this.courseId, this.questionId, this.selectedQuesVersion);
          this.questionVersionReports = result as QuestionReportsResponse;
          this.exams = this.questionVersionReports?.reports?.exams as ExamReport[];

          this.sortReport(this.questionVersionReports?.reports?.answerTimeDistributionReport as AnswerTimeDistributionReport[][]);
          this.sortReport(this.questionVersionReports?.reports?.itemAnalysisReport as ItemAnalysisReport[][]);

          this.calcReportsAverage();
        }
        this.versioningLoading = false;
      }
    } catch (err) {
      this.questionVersionReports = null;
      this.versioningLoading = false;
    }
  }

  get answerTimeDistributionReportLength(): number {
    return this.questionVersionReports?.reports?.answerTimeDistributionReport
      ? this.questionVersionReports?.reports?.answerTimeDistributionReport[0].length
      : 0;
  }

  get itemAnalysisReportLength(): number {
    return this.questionVersionReports?.reports?.itemAnalysisReport
      ? this.questionVersionReports?.reports?.itemAnalysisReport[0].length
      : 0;
  }

  calcReportsAverage(): void {
    const answerTimeDistribution = this.questionVersionReports?.reports?.answerTimeDistributionReport as AnswerTimeDistributionReport[][];
    const itemAnalysis = this.questionVersionReports?.reports?.itemAnalysisReport as ItemAnalysisReport[][];

    if (this.question?.basicData?.type?.id === QUESION_TYPES.CASE_STUDY.id) {
      this.calcMongoAverages(itemAnalysis, answerTimeDistribution);
    } else if (this.question?.basicData?.type?.id === QUESION_TYPES.MATCHING.id) {
      this.calcMongoAverages(itemAnalysis);
      this.calcStandardAverages(answerTimeDistribution[0]);
    } else {
      this.calcStandardAverages(answerTimeDistribution[0], itemAnalysis[0]);
    }
  }

  calcMongoAverages(itemAnalysis: ItemAnalysisReport[][], answerTimeDistribution: AnswerTimeDistributionReport[][] = []): void {
    const examIds: string[] = [];
    itemAnalysis.forEach((report) => {
      if (report[0] && report[0].examId && !examIds.includes(report[0].examId)) {
        examIds.push(report[0].examId);
      }
    });
    const examsCount = examIds.length;
    const reports = {};

    for (let i = 0; i < examsCount; i += 1) {
      const subQuestionsCount = itemAnalysis[i].length;
      for (let j = 0; j < subQuestionsCount; j += 1) {
        if (!reports[itemAnalysis[i][j].subQuestionId as string]) reports[itemAnalysis[i][j].subQuestionId as string] = {};

        if (!reports[itemAnalysis[i][j].subQuestionId as string].difficultyIndex && reports[itemAnalysis[i][j].subQuestionId as string].difficultyIndex !== 0) { reports[itemAnalysis[i][j].subQuestionId as string].difficultyIndex = 0; }
        reports[itemAnalysis[i][j].subQuestionId as string].difficultyIndex += parseFloat(itemAnalysis[i][j].difficultyIndex as string);

        if (!reports[itemAnalysis[i][j].subQuestionId as string].discriminationIndex && reports[itemAnalysis[i][j].subQuestionId as string].discriminationIndex !== 0) { reports[itemAnalysis[i][j].subQuestionId as string].discriminationIndex = 0; }
        reports[itemAnalysis[i][j].subQuestionId as string].discriminationIndex += parseFloat(itemAnalysis[i][j].discriminationIndex as string);

        if (!reports[itemAnalysis[i][j].subQuestionId as string].studentsCount && reports[itemAnalysis[i][j].subQuestionId as string].studentsCount !== 0) { reports[itemAnalysis[i][j].subQuestionId as string].studentsCount = 0; }
        reports[itemAnalysis[i][j].subQuestionId as string].studentsCount += itemAnalysis[i][j].studentsCount;

        if (!reports[itemAnalysis[i][j].subQuestionId as string].subQuestionType) { reports[itemAnalysis[i][j].subQuestionId as string].subQuestionType = itemAnalysis[i][j].subQuestionType; }

        if (!reports[itemAnalysis[i][j].subQuestionId as string].examsCount && reports[itemAnalysis[i][j].subQuestionId as string].examsCount !== 0) { reports[itemAnalysis[i][j].subQuestionId as string].examsCount = 0; }
        reports[itemAnalysis[i][j].subQuestionId as string].examsCount += 1;

        if (answerTimeDistribution.length > 0) {
          if (!reports[answerTimeDistribution[i][j].subQuestionId as string].minTime && reports[answerTimeDistribution[i][j].subQuestionId as string].minTime !== 0) {
            reports[answerTimeDistribution[i][j].subQuestionId as string].minTime = 0;
          }
          reports[answerTimeDistribution[i][j].subQuestionId as string].minTime += parseFloat(answerTimeDistribution[i][j].minTime as string);

          if (!reports[answerTimeDistribution[i][j].subQuestionId as string].maxTime && reports[answerTimeDistribution[i][j].subQuestionId as string].maxTime !== 0) {
            reports[answerTimeDistribution[i][j].subQuestionId as string].maxTime = 0;
          }
          reports[answerTimeDistribution[i][j].subQuestionId as string].maxTime += parseFloat(answerTimeDistribution[i][j].maxTime as string);

          if (!reports[answerTimeDistribution[i][j].subQuestionId as string].theMean && reports[answerTimeDistribution[i][j].subQuestionId as string].theMean !== 0) {
            reports[answerTimeDistribution[i][j].subQuestionId as string].theMean = 0;
          }
          reports[answerTimeDistribution[i][j].subQuestionId as string].theMean += parseFloat(answerTimeDistribution[i][j].theMean as string);

          if (!reports[answerTimeDistribution[i][j].subQuestionId as string].theMedian && reports[answerTimeDistribution[i][j].subQuestionId as string].theMedian !== 0) {
            reports[answerTimeDistribution[i][j].subQuestionId as string].theMedian = 0;
          }
          reports[answerTimeDistribution[i][j].subQuestionId as string].theMedian += parseFloat(answerTimeDistribution[i][j].theMedian as string);

          if (!reports[answerTimeDistribution[i][j].subQuestionId as string].theMode && reports[answerTimeDistribution[i][j].subQuestionId as string].theMode !== 0) {
            reports[answerTimeDistribution[i][j].subQuestionId as string].theMode = 0;
          }
          reports[answerTimeDistribution[i][j].subQuestionId as string].theMode += parseFloat(answerTimeDistribution[i][j].theMode as string);

          if (!reports[answerTimeDistribution[i][j].subQuestionId as string].standardDeviation && reports[answerTimeDistribution[i][j].subQuestionId as string].standardDeviation !== 0) {
            reports[answerTimeDistribution[i][j].subQuestionId as string].standardDeviation = 0;
          }
          reports[answerTimeDistribution[i][j].subQuestionId as string].standardDeviation += parseFloat(answerTimeDistribution[i][j].standardDeviation as string);

          if (!reports[answerTimeDistribution[i][j].subQuestionId as string].coefficientOfVariation && reports[answerTimeDistribution[i][j].subQuestionId as string].coefficientOfVariation !== 0) {
            reports[answerTimeDistribution[i][j].subQuestionId as string].coefficientOfVariation = 0;
          }
          reports[answerTimeDistribution[i][j].subQuestionId as string].coefficientOfVariation += parseFloat(answerTimeDistribution[i][j].coefficientOfVariation as string);
        }
      }
    }

    Object.keys(reports).forEach((subQuestionId) => {
      this.itemAnalysisAverages.push({
        subQuestionId,
        difficultyIndex: (reports[subQuestionId].difficultyIndex / reports[subQuestionId].examsCount).toFixed(this.fractionNumber),
        discriminationIndex: (reports[subQuestionId].discriminationIndex / reports[subQuestionId].examsCount).toFixed(this.fractionNumber),
        studentsCount: reports[subQuestionId].studentsCount,
        examsCount,
        subQuestionType: reports[subQuestionId].subQuestionType,
      });

      if (answerTimeDistribution.length > 0) {
        this.answerTimeDistributionAverages.push({
          subQuestionId,
          minTime: (reports[subQuestionId].minTime / reports[subQuestionId].examsCount).toFixed(this.fractionNumber),
          maxTime: (reports[subQuestionId].maxTime / reports[subQuestionId].examsCount).toFixed(this.fractionNumber),
          theMean: (reports[subQuestionId].theMean / reports[subQuestionId].examsCount).toFixed(this.fractionNumber),
          theMedian: (reports[subQuestionId].theMedian / reports[subQuestionId].examsCount).toFixed(this.fractionNumber),
          theMode: (reports[subQuestionId].theMode / reports[subQuestionId].examsCount).toFixed(this.fractionNumber),
          standardDeviation: (reports[subQuestionId].standardDeviation / reports[subQuestionId].examsCount).toFixed(this.fractionNumber),
          coefficientOfVariation: (reports[subQuestionId].coefficientOfVariation / reports[subQuestionId].examsCount).toFixed(this.fractionNumber),
          studentsCount: reports[subQuestionId].studentsCount,
          examsCount,
          subQuestionType: reports[subQuestionId].subQuestionType,
        });
      }
    });
  }

  calcStandardAverages(answerTimeDistribution: AnswerTimeDistributionReport[], itemAnalysis: ItemAnalysisReport[] = []): void {
    const examsIds: string[] = []; const
      reportsCount = answerTimeDistribution.length;

    let minTime = 0; let maxTime = 0; let theMean = 0; let theMedian = 0; let theMode = 0; let standardDeviation = 0; let coefficientOfVariation = 0;
    let studentsCount = 0; let difficultyIndex = 0; let
      discriminationIndex = 0;

    for (let i = 0; i < reportsCount; i += 1) {
      studentsCount += answerTimeDistribution[i].studentsCount as number;
      if (
        answerTimeDistribution[i]
        && answerTimeDistribution[i].examId
        && !examsIds.includes(answerTimeDistribution[i].examId as string)
      ) {
        examsIds.push(answerTimeDistribution[i].examId as string);
      }

      if (itemAnalysis.length > 0) {
        difficultyIndex += parseFloat(itemAnalysis[i].difficultyIndex as string);
        discriminationIndex += parseFloat(itemAnalysis[i].discriminationIndex as string);
      }

      minTime += parseFloat(answerTimeDistribution[i].minTime as string);
      maxTime += parseFloat(answerTimeDistribution[i].maxTime as string);
      theMean += parseFloat(answerTimeDistribution[i].theMean as string);
      theMedian += parseFloat(answerTimeDistribution[i].theMedian as string);
      theMode += parseFloat(answerTimeDistribution[i].theMode as string);
      standardDeviation += parseFloat(answerTimeDistribution[i].standardDeviation as string);
      coefficientOfVariation += parseFloat(answerTimeDistribution[i].coefficientOfVariation as string);
    }

    if (reportsCount) {
      this.answerTimeDistributionAverages.push({
        minTime: (minTime / reportsCount).toFixed(this.fractionNumber),
        maxTime: (maxTime / reportsCount).toFixed(this.fractionNumber),
        theMean: (theMean / reportsCount).toFixed(this.fractionNumber),
        theMedian: (theMedian / reportsCount).toFixed(this.fractionNumber),
        theMode: (theMode / reportsCount).toFixed(this.fractionNumber),
        standardDeviation: (standardDeviation / reportsCount).toFixed(this.fractionNumber),
        coefficientOfVariation: (coefficientOfVariation / reportsCount).toFixed(this.fractionNumber),
        studentsCount,
        examsCount: examsIds.length,
      });
    }

    if (reportsCount && itemAnalysis.length > 0) {
      this.itemAnalysisAverages.push({
        difficultyIndex: (difficultyIndex / reportsCount).toFixed(this.fractionNumber),
        discriminationIndex: (discriminationIndex / reportsCount).toFixed(this.fractionNumber),
        studentsCount,
        examsCount: examsIds.length,
      });
    }
  }

  sortReport(report: ItemAnalysisReport[][] | AnswerTimeDistributionReport[][]): void {
    if (report && report.length > 0) {
      if (report.length === 1) {
        report[0].sort((a, b) => {
          const bExam = this.exams.find((exam) => exam.examId === b.examId)?.examDate as string;
          const aExam = this.exams.find((exam) => exam.examId === a.examId)?.examDate as string;
          if (bExam > aExam) {
            return 1;
          } if (bExam < aExam) {
            return -1;
          }
          return 0;
        });
      } else if (report.length > 1) {
        report.sort((a, b) => {
          const bExam = this.exams.find((exam) => exam.examId === b[0].examId)?.examDate as string;
          const aExam = this.exams.find((exam) => exam.examId === a[0].examId)?.examDate as string;
          if (bExam > aExam) {
            return 1;
          } if (bExam < aExam) {
            return -1;
          }
          return 0;
        });
      }
    }
  }

  get itemAnalysisAveragesHeaders() {
    const itemAnalysisHeaders = [
      {
        text: this.$t('examsCount'),
        sortable: false,
        value: 'examsCount',
        align: 'center',
        class: this.averagesHeaderClass,
      },
      {
        text: this.$t('labels.TOTAL_STUDENTS'),
        sortable: false,
        value: 'studentsCount',
        align: 'center',
        class: this.averagesHeaderClass,
      },
      {
        text: this.$t('tableHeaders.DISCRIMINATION_INDEX_AVERAGE'),
        sortable: false,
        value: 'discriminationIndex',
        align: 'center',
        class: this.averagesHeaderClass,
      },
      {
        text: this.$t('tableHeaders.DIFFICULTY_INDEX_AVERAGE'),
        sortable: false,
        value: 'difficultyIndex',
        align: 'center',
        class: this.averagesHeaderClass,
      },
    ];

    if (this.question.basicData && this.question.basicData.type
      && [QUESION_TYPES.CASE_STUDY.id, QUESION_TYPES.MATCHING.id].includes(this.question.basicData.type.id)) {
      itemAnalysisHeaders.splice(0, 0, {
        text: this.$t('subQuestion'),
        sortable: false,
        align: 'center',
        value: 'subQuestionTitle',
        class: this.averagesHeaderClass,
      });
    }

    return itemAnalysisHeaders;
  }

  getItemAnalysisHeaders(index: number, report: ItemAnalysisReport[]) {
    const itemAnalysisHeaders = [
      {
        text: this.$t('labels.ExamTitle'),
        sortable: false,
        align: 'center',
        value: 'examTitle',
        class: this.headerClass,
        width: '25%',
      },
      {
        text: this.$t('MODEL_NUMBER'),
        sortable: false,
        align: 'center',
        value: 'modelIndex',
        class: this.headerClass,
      },
      {
        text: this.$t('labels.STUDENTS_COUNT'),
        sortable: false,
        value: 'studentsCount',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.DISCRIMINATION_INDEX'),
        sortable: false,
        value: 'discriminationIndex',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.DIFFICULTY_INDEX'),
        sortable: false,
        value: 'difficultyIndex',
        align: 'center',
        class: this.headerClass,
      },
    ];

    if (this.question.basicData && this.question.basicData.type
      && [QUESION_TYPES.CASE_STUDY.id, QUESION_TYPES.MATCHING.id].includes(this.question.basicData.type.id)) {
      itemAnalysisHeaders.splice(2, 0, {
        text: this.$t('subQuestion'),
        sortable: false,
        align: 'center',
        value: 'subQuestionTitle',
        class: this.headerClass,
        width: this.question.basicData.type.id === QUESION_TYPES.CASE_STUDY.id
          ? '15%'
          : 'auto',
      });
    }

    if (this.question && this.question.answers) {
      this.answersCount[index] = 0;
      report.forEach((row) => {
        this.answersCount[index] = row.answers && row.answers.length > this.answersCount[index]
          ? row.answers.length
          : this.answersCount[index];
      });

      if (this.question.basicData && this.question.basicData.type && QUESION_TYPES.ESSAY.id !== this.question.basicData.type.id) {
        for (let i = 0; i < this.answersCount[index]; i += 1) {
          itemAnalysisHeaders.push({
            text: this.question.basicData.type.id === QUESION_TYPES.MATCHING.id
              ? i + 1
              : this.$options.filters?.servertranslate(ANSWER_CHOICES[i]),
            sortable: false,
            value: `answers[${i}]`,
            align: 'center',
            class: this.headerClass,
          });
        }
      }
    }
    return itemAnalysisHeaders;
  }

  get answerTimeDistributionAveragesHeaders() {
    const timeDistributionHeaders = [
      {
        text: this.$t('examsCount'),
        sortable: false,
        value: 'examsCount',
        align: 'center',
        class: this.averagesHeaderClass,
      },
      {
        text: this.$t('labels.TOTAL_STUDENTS'),
        sortable: false,
        value: 'studentsCount',
        align: 'center',
        class: this.averagesHeaderClass,
      },
      {
        text: this.$t('tableHeaders.MIN_TIME_AVERAGE'),
        sortable: false,
        value: 'minTime',
        align: 'center',
        class: this.averagesHeaderClass,
      },
      {
        text: this.$t('tableHeaders.MAX_TIME_AVERAGE'),
        sortable: false,
        value: 'maxTime',
        align: 'center',
        class: this.averagesHeaderClass,
      },
      {
        text: this.$t('tableHeaders.MEAN_AVERAGE'),
        sortable: false,
        value: 'theMean',
        align: 'center',
        class: this.averagesHeaderClass,
      },
      {
        text: this.$t('tableHeaders.MEDIAN_AVERAGE'),
        sortable: false,
        value: 'theMedian',
        align: 'center',
        class: this.averagesHeaderClass,
      },
      {
        text: this.$t('tableHeaders.MODE_AVERAGE'),
        sortable: false,
        value: 'theMode',
        align: 'center',
        class: this.averagesHeaderClass,
      },
      {
        text: this.$t('tableHeaders.STANDARD_DEVIATION_AVERAGE'),
        sortable: false,
        value: 'standardDeviation',
        align: 'center',
        class: this.averagesHeaderClass,
      },
      {
        text: this.$t('tableHeaders.COEFFICIENT_OD_VARIATION_AVERAGE'),
        sortable: false,
        value: 'coefficientOfVariation',
        align: 'center',
        class: this.averagesHeaderClass,
      },
    ];

    if (this.question.basicData && this.question.basicData.type
      && QUESION_TYPES.CASE_STUDY.id === this.question.basicData.type.id) {
      timeDistributionHeaders.splice(0, 0, {
        text: this.$t('subQuestion'),
        sortable: false,
        align: 'center',
        value: 'subQuestionTitle',
        class: this.averagesHeaderClass,
      });
    }

    return timeDistributionHeaders;
  }

  get answerTimeDistributionHeaders() {
    const timeDistributionHeaders = [
      {
        text: this.$t('labels.ExamTitle'),
        sortable: false,
        align: 'center',
        value: 'examTitle',
        class: this.headerClass,
        width: '25%',
      },
      {
        text: this.$t('MODEL_NUMBER'),
        sortable: false,
        align: 'center',
        value: 'modelIndex',
        class: this.headerClass,
      },
      {
        text: this.$t('labels.STUDENTS_COUNT'),
        sortable: false,
        value: 'studentsCount',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.MIN_TIME'),
        sortable: false,
        value: 'minTime',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.MAX_TIME'),
        sortable: false,
        value: 'maxTime',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.MEAN'),
        sortable: false,
        value: 'theMean',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.MEDIAN'),
        sortable: false,
        value: 'theMedian',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.MODE'),
        sortable: false,
        value: 'theMode',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.STANDARD_DEVIATION'),
        sortable: false,
        value: 'standardDeviation',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.COEFFICIENT_OD_VARIATION'),
        sortable: false,
        value: 'coefficientOfVariation',
        align: 'center',
        class: this.headerClass,
      },
    ];

    if (this.question.basicData && this.question.basicData.type
      && QUESION_TYPES.CASE_STUDY.id === this.question.basicData.type.id) {
      timeDistributionHeaders.splice(2, 0, {
        text: this.$t('subQuestion'),
        sortable: false,
        align: 'center',
        value: 'subQuestionTitle',
        class: this.headerClass,
        width: '15%',
      });
    }

    return timeDistributionHeaders;
  }
}

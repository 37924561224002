

































































































































































import { Component, Prop } from 'vue-property-decorator';
import IlosComponet from '@/shared/ilos-view-component.vue';
import MathJaxMixin from '../../mixins/mathJax.mixin';
import { QuestionBasicData } from '../../types/questions.type';
import { VALID_DIFFICULTY_RANGE, VALID_TIME_RANGE } from '../../enums';
import Utils from '../../shared/utils';

@Component({
  components: { IlosComponet },
})
export default class SubQuestionsPanels extends MathJaxMixin {
    @Prop(Array) readonly subQuestions!: QuestionBasicData[];

    @Prop(Boolean) readonly isFlexibleILOs!: boolean;

    get appDrawerOpen() {
      return !this.$store.state.app.mini;
    }

    getStringTime(averageTime: string | number): string {
      return Utils.getStringTime(averageTime);
    }

    difficultyNeedToFix(difficulty: number, averageDifficulty: number): boolean {
      const topDifficulty = difficulty ? difficulty + difficulty * VALID_DIFFICULTY_RANGE : VALID_DIFFICULTY_RANGE;
      const bottomDifficulty = difficulty ? difficulty - difficulty * VALID_DIFFICULTY_RANGE : 0;
      return (!!averageDifficulty || averageDifficulty === 0)
        && (topDifficulty < averageDifficulty || bottomDifficulty > averageDifficulty);
    }

    timeNeedToFix(timeInMinutes: number, averageTime: number): boolean {
      const topTime = timeInMinutes ? timeInMinutes + timeInMinutes * VALID_TIME_RANGE : VALID_TIME_RANGE;
      const bottomTime = timeInMinutes ? timeInMinutes - timeInMinutes * VALID_TIME_RANGE : 0;
      return (!!averageTime || averageTime === 0) && (topTime < averageTime || bottomTime > averageTime);
    }
}
